<template>
  <div class="logo">
    <div class="logo_font bottom_font">重置密码</div>
    <div class="inputbox">
      <input
        class="inputinbox"
        type="number"
        v-model="formData.mobile"
        @blur="phonejc"
        placeholder="请输入手机号码"
      />
    </div>
    <div class="inputbox">
      <input class="input_left" placeholder="请输入验证码" v-model="formData.code" />
      <div class="input_right">
        <div
          class="yzm_send"
          @click="sendyzm"
          v-if="yzmtype == 1 || yzmtype == 3"
        >
          {{ yzmtype == 1 ? "获取验证码" : "重新获取" }}
        </div>
        <div class="yzm_send" v-if="yzmtype == 2">{{ num }}s</div>
      </div>
    </div>
    <div class="inputbox">
      <input
        class="inputinbox"
        type="password"
        v-model="formData.password1"
        @blur="passwordjc"
        placeholder="请输入新密码"
      />
    </div>
    <div class="inputbox">
      <input
        class="inputinbox"
        type="password"
        v-model="formData.password2"
        @blur="passwordjc2"
        placeholder="请重新输入新密码"
      />
    </div>
    <div class="login_btn" @click="xgsubmit">确认修改</div>
  </div>
</template>
<script>
import { Notify } from "vant";
export default {
  data() {
    return {
      yzmtype: 1,
      num: 60,
      timer: "",
      formData: {},
    };
  },
  methods: {
    //发送验证码
    sendyzm() {
      console.log(this.formData.mobile);
      if (this.formData.mobile) {
        this.$http.msgSend({ mobile: this.formData.mobile }).then((res) => {
          console.log(res);
        });
        this.yzmtype = 2;
        this.timer = setInterval(() => {
          this.num = this.num - 1;
          if (this.num <= 0) {
            clearInterval(this.timer);
            this.num = 60;
            this.yzmtype = 3;
          }
        }, 1000);
      } else {
        Notify({ type: "primary", message: "请先输入手机号" });
      }
    },
    //检测手机号
    phonejc() {
      console.log(this.formData);
      if (/^1[3-9]\d{9}$/.test(this.formData.mobile)) {
      } else {
        this.formData.mobile = "";
        Notify({ type: "primary", message: "手机号格式错误" });
      }
    },
    //密码格式验证
    passwordjc() {
      if (
        /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,20}$/.test(
          this.formData.password1
        )
      ) {
        console.log("正确");
      } else {
        this.formData.password1 = "";
        Notify({
          type: "primary",
          message: "密码必须由数字和字母组成长度为6-20位",
        });
      }
    },
    //检测手机号密码是否一致
    passwordjc2() {
      if (this.formData.password1 == this.formData.password2) {
        console.log("正确");
      } else {
        this.formData.password2 = "";
        console.log(this.formData);
        Notify({ type: "primary", message: "二次密码输入不一致" });
      }
    },
    //修改密码
    xgsubmit() {
      console.log(this.formData);
      if(this.formData.mobile&&this.formData.code&&this.formData.password2){
        this.$http.updatePwd({mobile:this.formData.mobile,code:this.formData.code,newPwd:this.formData.password2}).then(res=>{
          console.log(res)
        })
      }else{
        Notify({ type: "primary", message: "请完整填入所需信息" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  overflow-y: auto;
  padding: 0 30px;
  padding-top: 84px;
  box-sizing: border-box;
  .bottom_font {
    margin-bottom: 24px !important;
  }
  .logo_font {
    width: 315px;
    margin: 0 auto;
    margin-bottom: 16px;
    font-weight: 500;
    color: #333333;
    font-size: 24px;
  }
  .inputbox {
    width: 315px;
    margin: 0 auto;
    margin-bottom: 16px;
    height: 48px;

    background: rgba(0, 112, 251, 0.05);
    border-radius: 4px;
    display: flex;
    .inputinbox {
      width: 100%;
      height: 100%;

      background: rgba(0, 112, 251, 0.05);
      font-size: 16px;
      padding-left: 16px;
      box-sizing: border-box;
    }
  }
  .login_btn {
    margin: 0 auto;
    margin-top: 32px;
    width: 315px;
    height: 47px;
    background: #1890ff;
    border-radius: 24px;
    font-weight: 500;
    color: #ffffff;
    font-size: 16px;
    line-height: 47px;
    text-align: center;
  }
  .change_font {
    width: 315px;
    text-align: center;
    margin: 0 auto;
    margin-top: 32px;
    font-weight: 500;
    color: #0070fb;
    font-size: 14px;
  }
  .input_left {
    width: 191px;
    height: 100%;
    font-size: 16px;
    padding-left: 16px;
    box-sizing: border-box;
    background: rgba(0, 112, 251, 0.05);
  }
  .input_right {
    height: 100%;
    flex: 1;
    background: rgba(0, 112, 251, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    .yzm_send {
      width: 100%;
      border-left: 1px solid #bac4c9;
      font-weight: 500;
      color: #333333;
      font-size: 12px;
      text-align: center;
    }
  }
  .remember {
    width: 315px;
    margin: 0 auto;
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    align-items: center;
    .rem_left {
      display: flex;
      align-items: center;
      justify-content: center;
      .rem_top {
        margin-right: 8px;
        width: 14px;
        height: 14px;
        border: 1px solid #ccc;
        border-radius: 50%;
      }
      .rem_top2 {
        margin-right: 8px;
        width: 14px;
        height: 14px;
        border: 1px solid #1890ff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .intop {
          width: 10px;
          height: 10px;
          background: #1890ff;
          border-radius: 50%;
        }
      }
      .rem_bottom {
        margin-top: 2px;
      }
    }
  }
}
</style>